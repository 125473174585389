<template>
  <div class="mine messages">
    <div class="message last ios-font" style="min-width: 188px">
      <div v-if="props.headerText != ''">
        <p
          style="margin: 2px 6px 8px 6px; font-size: 14px"
          class="fw-bold lh-sm"
        >
          {{ props.headerText }}
        </p>
      </div>
      <div>
        <img
          v-if="media && mediaType === 'image'"
          alt="Image Preview"
          :src="media"
          width="171"
          height="110"
          style="border-radius: 1rem"
        />
        <video
          v-if="media && mediaType == 'video'"
          :src="media"
          controls
          width="171"
          height="110"
          style="border-radius: 1rem"
        ></video>

        <div
          v-if="media && mediaType == 'document'"
          style="
            width: 171px;
            height: 110px;
            border-radius: 1rem;
            background-color: #dddddd;
          "
          class="d-flex justify-content-center"
        >
          <i class="bi bi-file-earmark-pdf-fill" style="font-size: 65px"></i>
        </div>
      </div>
      <div :class="{ 'mt-2': media }">
        <p style="margin: 2px 6px" class="lh-sm">{{ previewMessage }}</p>
      </div>
      <div
        class="mt-2 row"
        style="padding: 2px 6px"
        v-if="props.footerText !== ''"
      >
        <div class="col-7 pe-0">
          <p style="font-size: 10px" class="lh-sm">{{ footerText }}</p>
        </div>
      </div>

      <div v-if="props.buttons && props.buttons.length">
        <div
          v-for="(button, index) in props.buttons"
          :key="index"
          style="
            border-top: 0.2px solid rgba(255, 255, 255, 0.4);
            padding-top: 4px;
            padding-bottom: 4px;
          "
          class="text-center"
        >
          <p class="lh-1 fw-500" style="font-size: 12px">
            <i :class="getButtonIcon(button.specification)" class="me-1"></i
            >{{ button.text.trim() !== "" ? button.text : "enter button text" }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, defineProps } from "vue";

const props = defineProps({
  media: {
    type: String,
  },
  userInput: {
    type: String,
  },
  mediaType: {
    type: String,
  },
  buttons: {
    type: Array,
    default: () => [],
  },
  headerText: {
    type: String,
    default: () => "",
  },
  footerText: {
    type: String,
    default: () => "",
  },
});

const getButtonIcon = (specification) => {
  switch (specification) {
    case "phone_number":
      return "bi bi-telephone";
    case "url":
      return "bi bi-arrow-up-right-square";
    case "quick_reply":
      return "bi bi-reply";
    default:
      return "";
  }
};

const previewMessage = computed(() =>
  props.userInput === ""
    ? "Insert your message and see it previewed here."
    : props.userInput
);
</script>
<style scoped>
.messages {
  display: flex;
  flex-direction: column;
}

.message {
  word-wrap: break-word;
  border-radius: 1.25rem;
  padding: 0.5rem 0.5rem;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  display: inline-block;
  max-width: 75%;
}

.mine {
  align-items: flex-end;
  margin-right: 1rem;
}

.mine .message {
  color: white;
  font-size: 0.75rem;
  font-weight: 600;
  margin-left: 2%;
  background-color: #00ca48;
  background-attachment: fixed;
  position: relative;
}

.mine .message.last:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: -0.5rem;
  height: 1.25rem;
  width: 1.25rem;
  background: #00ca48 fixed;
  border-bottom-left-radius: 1rem;
}

.mine .message.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: -0.625rem;
  width: 0.625rem;
  height: 1.25rem;
  background: white;
  border-bottom-left-radius: 0.625rem;
}
</style>
