<template>
  <n-drawer v-model:show="active" :width="400" :placement="placement">
    <div class="p-3 d-flex flex-column h-100">
      <div class="h3 fw-bold" @click="navigateTo('/')" style="cursor: pointer">
        Home
      </div>
      <div
        class="h3 fw-bold d-flex align-items-center"
        style="cursor: pointer"
        @click="show.myProjects = !show.myProjects"
      >
        <div>My Projects</div>
        <i
          class="bi bi-chevron-down ms-2 fs-6 fw-bold chevron-icon mb-2"
          style="margin-top: 0.8rem"
          :class="{ 'rotate-chevron': show.myProjects }"
        ></i>
      </div>

      <n-collapse-transition :show="show.myProjects">
        <div
          class="h5 fw-bold"
          @click="navigateTo('/ws')"
          style="cursor: pointer"
        >
          WhatsApp Link Generator
        </div>
        <div
          class="h5 fw-bold mb-4"
          @click="navigateTo('/poke')"
          style="cursor: pointer"
        >
          Pokemon Quiz
        </div>
      </n-collapse-transition>

      <div
        class="h3 fw-bold"
        @click="navigateTo('/work')"
        style="cursor: pointer"
      >
        My Work Experience
      </div>

      <div
        class="h3 fw-bold"
        @click="navigateTo('/edu')"
        style="cursor: pointer"
      >
        My Education
      </div>
      <div
        class="h3 fw-bold"
        @click="navigateTo('/projects')"
        style="cursor: pointer"
      >
        My Projects
      </div>

      <div class="h3 fw-bold mb-0" style="margin-top: auto">Hire Me!</div>
      <div class="d-flex">
        <a href="mailto:joeylim016@gmail.com" style="outline: none">
          <i
            class="bi bi-envelope fs-3 me-3"
            style="color: #c1c1c1; cursor: pointer"
          ></i>
        </a>
        <a href="https://wa.me/+60163035651" target="_blank">
          <i
            class="bi bi-whatsapp fs-3 me-3"
            style="color: #48c658; cursor: pointer"
          ></i>
        </a>
        <a href="https://www.linkedin.com/in/joeylimjunyi/" target="_blank">
          <i
            class="bi bi-linkedin fs-3 me-3"
            style="color: #0a63bd; cursor: pointer"
          ></i>
        </a>
      </div>
    </div>
  </n-drawer>
</template>

<script setup>
import { ref, defineProps, watch, defineEmits } from "vue";
import { NDrawer, NCollapseTransition } from "naive-ui";
import { useRouter } from "vue-router";

const emit = defineEmits(["dismissNav"]);
const show = ref({
  myProjects: false,
  contacts: false,
});

const active = ref(false);
const placement = "right";
const router = useRouter();

const props = defineProps({
  active: {
    type: Boolean,
    required: false,
  },
});

watch(
  () => props.active,
  (newVal) => {
    active.value = newVal;
  }
);

watch(active, (newVal) => {
  if (!newVal) {
    emit("dismissNav");
  }
});

function navigateTo(route) {
  router.push(route);
}
</script>

<style scoped>
.drawer-trigger {
  position: absolute;
  top: 10px;
  right: 10px;
}

.rotate-chevron {
  transition: transform 0.3s ease;
  transform: rotate(180deg);
}

.chevron-icon {
  transition: transform 0.3s ease;
}
</style>
