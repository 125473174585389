<template>
  <div style="height: 10px; background-color: #28a745"></div>

  <div class="d-flex justify-content-center">
    <Row class="px-3 py-3 d-flex justify-content-around" style="width: 70%">
      <Col sm="12" lg="5" class="mt-4">
        <div class="h2 fw-bold">WhatsApp Link Generator</div>

        <div class="text-muted" style="font-size: 0.875rem">
          <div>
            Chat with any phone number without saving it to your contacts.
          </div>
          <div>Simply enter the phone number and your desired message,</div>
          <div>click "Generate Link," and you're all set!</div>
        </div>

        <div class="h6 mt-5">Text Message</div>
        <div style="position: relative">
          <textarea
            type="text"
            class="form-control"
            v-model="messageInput"
          ></textarea>
          <i
            class="bi bi-check-circle-fill position-absolute check-icon"
            v-if="messageInput"
          ></i>
        </div>

        <div class="h6 mt-5">Phone Number</div>
        <div class="input-group mb-3">
          <button
            class="btn border text-start pe-0"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style="width: 200px"
          >
            <Row>
              <Col col="10" class="pe-0">
                <span>
                  {{ selectedCountry.name }} ({{ selectedCountry.code }})</span
                >
              </Col>
              <Col col="2" class="p-0">
                <i class="bi bi-chevron-down"></i>
              </Col>
            </Row>
          </button>
          <ul class="dropdown-menu" style="width: 200px">
            <li v-for="country in countries" :key="country.label">
              <a class="dropdown-item" @click="selectCountry(country)">
                {{ country.name }} ({{ country.code }})
              </a>
            </li>
          </ul>
          <input
            type="text"
            class="form-control"
            aria-label="Text input with dropdown button"
            v-model="phoneInput"
          />
          <i
            class="bi bi-check-circle-fill position-absolute check-icon"
            v-if="validNumber"
          ></i>
        </div>

        <Col col="12" class="mt-5">
          <Button
            class="w-100 btn btn-primary border text-white py-2"
            style="background-color: #01ca48; font-weight: 600"
            :disabled="!isButtonEnabled"
            @click="modal.show()"
            >Generate Link</Button
          ></Col
        >
      </Col>
      <Col
        col="1"
        class="d-flex flex-column align-items-center justify-content-center"
      >
        <i class="bi bi-chevron-right fs-2 fw-bold"></i>
        <div style="width: 150px; font-size: 0.875rem" class="text-center">
          this is how your message will look!
        </div>
      </Col>
      <Col
        col="4"
        class="d-flex justify-content-end"
        style="max-width: fit-content"
      >
        <MockupPhone
          style="margin-right: 0; margin-left: 0; max-height: 390px"
          :style="{ transform: 'scale(1.2)' }"
          class="mt-5"
          :full-phone-number="fullPhoneNumber"
          :valid-number="validNumber"
        >
          <Message :user-input="messageInput" />
        </MockupPhone>
      </Col>
    </Row>
  </div>

  <ModalContainer id="whatsappModal" animation="fade" keyboard="false">
    <ModalDialog size="lg">
      <ModalContent>
        <ModalBody>
          <Col col="12" class="text-center">
            <div class="h2">Your WhatsApp Link is Ready!</div>
            <div class="text-muted" style="font-size: 0.875rem">
              Use the link below or scan the QR code to send your WhatsApp
              message!
            </div>

            <Row>
              <Col
                class="p-3 d-flex justify-content-between mx-4 mt-4"
                style="background: rgb(248, 249, 251)"
              >
                <a
                  :href="whatsappLink"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="fs-5"
                >
                  {{ whatsappLink }}
                </a>
                <i class="bi bi-check-circle ms-auto fs-5" v-if="hasCopied"></i>

                <i
                  class="bi bi-clipboard ms-1 fs-5"
                  v-else
                  @click="copyToClipboard"
                ></i>
              </Col>

              <Col col="12" class="d-flex justify-content-center">
                <qrcode-vue :value="whatsappLink" size="250" class="mt-4"
              /></Col>
            </Row>
          </Col>
        </ModalBody>
      </ModalContent>
    </ModalDialog>
  </ModalContainer>

  <SideNavWithButton icon-color="#28a745" />
</template>

<script setup>
import MockupPhone from "@/components/WhatsappPhone/MockupPhone.vue";
import Message from "@/components/WhatsappPhone/Message.vue";
import Col from "@/components/layout/Col.vue";
import Row from "@/components/layout/Row.vue";
import Button from "@/components/uielements/button/Button.vue";
import {
  isValidPhoneNumber,
  parsePhoneNumberFromString as parsePhoneNumber,
} from "libphonenumber-js";
import ModalContainer from "@/components/uielements/modal/ModalContainer.vue";
import ModalContent from "@/components/uielements/modal/ModalContent.vue";
import ModalBody from "@/components/uielements/modal/ModalBody.vue";
import ModalDialog from "@/components/uielements/modal/ModalDialog.vue";
import QrcodeVue from "qrcode.vue";
import SideNavWithButton from "@/components/SideNav/SideNavWithButton.vue";

import { ref, watch, computed, onMounted } from "vue";
import { Modal } from "bootstrap";

const validNumber = ref(false);
const phoneInput = ref("");
const messageInput = ref("");

const fullPhoneNumber = ref("");

watch(phoneInput, (newValue) => {
  if (newValue) {
    validNumber.value = isValidPhoneNumber(
      phoneInput.value,
      selectedCountry.value.label
    );

    let phoneNumber;

    phoneNumber = parsePhoneNumber(
      phoneInput.value,
      selectedCountry.value.label
    );

    if (phoneNumber) {
      fullPhoneNumber.value = phoneNumber.formatInternational();
    }
  }
});

const countries = ref([
  { label: "MY", name: "Malaysia", code: "+60" },
  { label: "SG", name: "Singapore", code: "+65" },
  { label: "US", name: "United States", code: "+1" },
]);

const selectedCountry = ref(countries.value[0]);

const selectCountry = (country) => {
  selectedCountry.value = country;
};

const isButtonEnabled = computed(() => {
  return validNumber.value && messageInput.value;
});

const whatsappLink = computed(() => {
  if (validNumber.value && messageInput.value) {
    const encodedMessage = encodeURIComponent(messageInput.value);
    const phoneNumber = fullPhoneNumber.value.replace(/\s+/g, "");
    return `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
  }
  return "";
});

const modal = ref(null);
onMounted(() => {
  modal.value = new Modal("#whatsappModal");
});

const hasCopied = ref(false);

const copyToClipboard = () => {
  if (whatsappLink.value) {
    navigator.clipboard
      .writeText(whatsappLink.value)
      .then(() => {
        hasCopied.value = true;
        setTimeout(() => (hasCopied.value = false), 2000);
      })
      .catch((err) => console.error("Failed to copy: ", err));
  }
};
</script>

<style scoped>
.input-group {
  position: relative;
}

.check-icon {
  right: 10px;
  top: 50%;
  z-index: 999;
  transform: translateY(-50%);
  pointer-events: none;
  color: #28a745;
}
</style>
