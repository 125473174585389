<template>
  <div class="ms-3 mt-3">
    Insert Prompt
    <div class="w-25 d-flex">
      <input v-model="userInput" type="text" class="form-control" />
      <button class="btn btn-primary ms-2" @click="main">Submit</button>
    </div>

    <div class="mt-4">Response</div>

    <div class="w-50">
      <div class="card" style="min-height: 200px">
        <div class="card-body">
          <p>{{ response }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const userInput = ref("");
const response = ref(null);

import Groq from "groq-sdk";

const client = new Groq({
  apiKey: "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",
  dangerouslyAllowBrowser: true,
});

async function main() {
  const chatCompletion = await client.chat.completions.create({
    messages: [{ role: "user", content: userInput.value }],
    model: "llama3-8b-8192",
  });

  console.log(chatCompletion.choices[0].message.content);
  response.value = chatCompletion.choices[0].message.content;
}
</script>

<style scoped>
/* Add your styles here if needed */
</style>
