import { createRouter, createWebHistory } from "vue-router";
import LearnMore from "@/pages/LearnMore.vue";
import Landing from "@/pages/Index.vue";

import CreateWhatsappLink from "@/pages/Projects/CreateWhatsappLink/Index.vue";
import Pokenator from "@/pages/Projects/PokeNator/Index.vue";
import Joey from "./Joey.vue";
import MyWork from "@/pages/MyWork/Index.vue";
import MyEducation from "@/pages/MyEducation/Index.vue";
import MyProjects from "@/pages/MyProjects/Index.vue";

const routes = [
  { path: "/learn-more", component: LearnMore },
  { path: "/", component: Landing },
  { path: "/ws", component: CreateWhatsappLink },
  { path: "/poke", component: Pokenator },
  { path: "/joey", component: Joey },
  { path: "/work", component: MyWork },
  { path: "/edu", component: MyEducation },
  { path: "/projects", component: MyProjects },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
