<template>
  <Row class="d-flex justify-content-center" v-if="!loading">
    <div style="width: 70%">
      <div v-if="!selectedPokemon" class="text-center">
        <div class="py-4 h2 fw-bold">Choose your favorite Pokémon!</div>

        <input
          v-model="searchQuery"
          class="form-control mb-4 w-25"
          style="margin-left: 2.5rem"
          placeholder="Search for a Pokémon..."
        />
        <div class="pokemon-grid justify-content-center">
          <div
            v-for="poke in filteredPokemon"
            :key="poke.name"
            class="pokemon-item rounded"
            style="border: 1px solid #1c1c1c"
          >
            <p class="m-0 mt-2" style="font-size: 0.875rem">
              {{ capitalize(poke.name) }}
            </p>
            <img
              style="cursor: pointer"
              :src="poke.sprite"
              :alt="poke.name"
              @click="selectPokemon(poke)"
            />
          </div>
        </div>
      </div>

      <div v-if="selectedPokemon && !quizEnded" class="text-center">
        <h2>How well do you know your favourite Pokemon?</h2>

        <div>
          <img
            :src="selectedPokemon.sprite"
            :alt="selectedPokemon.name"
            style="width: 200px; height: 200px"
          />
        </div>
        <div class="h2">
          {{ capitalize(selectedPokemon.name) }}
        </div>

        <div class="d-flex justify-content-center">
          <button
            @click="startQuiz"
            class="btn btn-primary w-50"
            v-if="!quizStarted"
          >
            Start Quiz
          </button>
        </div>
      </div>

      <div v-if="quizStarted">
        <div v-if="currentQuestion">
          <p>
            Question {{ currentQuestionIndex + 1 }} of {{ questions.length }}
          </p>

          <p>{{ currentQuestion.question }}</p>
          <div
            class="card mb-3"
            v-for="option in currentQuestion.options"
            :key="option"
            :class="{
              'bg-dark text-white': selectedAnswer === option,
            }"
            @click="selectAnswer(option)"
          >
            <div class="card-body">
              {{ option }}
            </div>
          </div>

          <Button
            @click="goToNextQuestion"
            class="btn btn-primary"
            :disabled="!selectedAnswer"
          >
            Next Question
          </Button>
        </div>
      </div>
      <div v-if="quizEnded" class="text-center">
        <h2>You've gotten {{ score }} questions right!</h2>

        <div v-if="score <= 1">
          <div>
            <i class="bi bi-emoji-tear" style="font-size: 10rem"></i>
          </div>
          <h2>Looks like you don't know your favorite Pokemon that well...</h2>
        </div>
        <div v-else>
          <div>
            <i class="bi bi-emoji-laughing" style="font-size: 10rem"></i>
          </div>
          <h2>You're a true Pokemon master!</h2>
        </div>

        <Button class="btn btn-primary" @click="restartQuiz">
          Restart Quiz
        </Button>
      </div>
    </div>
  </Row>
  <Row
    class="d-flex justify-content-center align-items-center"
    v-else
    style="height: 100vh"
  >
    <Spinner
      size="lg"
      variant="primary"
      style="width: 3rem; height: 3rem; border-width: 6px"
    />
  </Row>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import axios from "axios";
import Row from "@/components/layout/Row.vue";
import Spinner from "@/components/uielements/spinner/Spinner.vue";

// Reactive variables
const pokemonList = ref([]);
const selectedPokemon = ref(null);
const quizStarted = ref(false);
const quizEnded = ref(false);
const currentQuestionIndex = ref(0);
const score = ref(0);
const questions = ref([]); // To hold the quiz questions
const currentQuestion = ref(null);
const searchQuery = ref(""); // New: For user search input
const loading = ref(true);

// Fetch first-generation Pokémon data
const fetchPokemonList = async () => {
  try {
    const response = await axios.get(
      `https://pokeapi.co/api/v2/pokemon?limit=151`
    );

    // Fetch details for each Pokémon to get the sprites and urls
    const pokemonDetails = await Promise.all(
      response.data.results.map(async (pokemon) => {
        const details = await axios.get(pokemon.url);
        return {
          name: pokemon.name,
          url: pokemon.url, // Include the URL
          sprite: details.data.sprites.front_default, // Include the sprite
        };
      })
    );

    pokemonList.value = pokemonDetails;
  } catch (error) {
    console.error("Error fetching Pokémon list:", error);
  } finally {
    // Simulate loading state
    setTimeout(() => {
      loading.value = false;
    }, 2000); // Set loading to false after 5 seconds
  }
};

// Select a favorite Pokémon
const selectPokemon = async (poke) => {
  selectedPokemon.value = poke;
  await fetchPokemonData(poke.url);
};

// Fetch detailed data for the selected Pokémon
const fetchPokemonData = async (url) => {
  try {
    const response = await axios.get(url);
    // Prepare quiz questions based on Pokémon data

    prepareQuiz(response.data);
  } catch (error) {
    console.error("Error fetching Pokémon data:", error);
  }
};

// Prepare 5 quiz questions about the selected Pokémon
const prepareQuiz = (pokemonData) => {
  questions.value = [
    {
      question: "What is the base HP of this Pokémon?",
      options: [
        pokemonData.stats[0].base_stat,
        pokemonData.stats[0].base_stat + 10,
        pokemonData.stats[0].base_stat - 10,
      ],
      correctAnswer: pokemonData.stats[0].base_stat,
    },
    {
      question: "What is the type of this Pokémon?",
      options: [pokemonData.types[0].type.name, "wood", "metal"],
      correctAnswer: pokemonData.types[0].type.name,
    },
    {
      question: "What is the weight of this Pokémon?",
      options: [
        pokemonData.weight - 10,
        pokemonData.weight,
        pokemonData.weight + 10,
      ],
      correctAnswer: pokemonData.weight,
    },
  ];

  currentQuestionIndex.value = 0;
  currentQuestion.value = questions.value[currentQuestionIndex.value];
};

// Start the quiz
const startQuiz = () => {
  quizStarted.value = true;
  quizEnded.value = false;
};

// Handle answer selection
const selectedAnswer = ref(null); // New: To track selected answer

const selectAnswer = (answer) => {
  selectedAnswer.value = answer;
  // Optionally, you can add more logic here if needed
};

// Initialize the component
onMounted(() => {
  fetchPokemonList();
});

// Helper function to capitalize Pokémon names
const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

// Filter Pokemon
const filteredPokemon = computed(() => {
  if (!searchQuery.value) return pokemonList.value; // Return full list if no search
  return pokemonList.value.filter((poke) =>
    poke.name.toLowerCase().includes(searchQuery.value.toLowerCase())
  );
});

// Go to the next question
const goToNextQuestion = () => {
  if (selectedAnswer.value === currentQuestion.value.correctAnswer) {
    score.value++;
  }

  if (currentQuestionIndex.value < questions.value.length - 1) {
    currentQuestionIndex.value++;
    currentQuestion.value = questions.value[currentQuestionIndex.value];
    selectedAnswer.value = null; // Reset selected answer
  } else {
    quizEnded.value = true;
    quizStarted.value = false;
  }
};

function restartQuiz() {
  selectedPokemon.value = null;
  quizStarted.value = false;
  quizEnded.value = false;
  currentQuestionIndex.value = 0;
  score.value = 0;
  questions.value = [];
  currentQuestion.value = null;
}
</script>

<style>
.pokemon-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
}

.pokemon-item {
  text-align: center;
  width: 100px;
  transition: transform 0.1s ease;
}

.pokemon-item img {
  width: 80px;
  height: 80px;
  transition: transform 0.1s ease;
}

.pokemon-item:hover {
  transform: scale(1.1);
}
</style>
