<template>
  <div class="box" ref="boxRef">
    <div class="row w-100 h-100">
      <div style="background-color: #3700e8; height: 10px"></div>

      <div
        class="col-12 col-lg-6 d-flex align-items-top justify-content-end px-5"
      >
        <div class="position-relative portrait-container">
          <img
            src="@/assets/potrait/right-brow.png"
            alt="Right Brow"
            class="eye-image right-brow"
            ref="rightBrow"
          />
          <img
            src="@/assets/potrait/face.png"
            alt="Face"
            class="face"
            ref="face"
          />
          <img
            src="@/assets/potrait/eye.png"
            alt="Left Eye"
            class="eye-image left-eye"
            ref="leftEye"
          />
          <img
            src="@/assets/potrait/eye.png"
            alt="Right Eye"
            class="eye-image right-eye"
            ref="rightEye"
          />
          <img
            src="@/assets/potrait/mouth.png"
            alt="Mouth"
            class="mouth"
            ref="mouth"
          />
          <img
            src="@/assets/potrait/left-brow.png"
            alt="Left Brow"
            class="eye-image left-brow"
            ref="leftBrow"
          />
        </div>
      </div>
      <div class="col-12 col-lg-6 d-flex align-items-center">
        <div class="content row hide-on-small-screens">
          <span class="intro-text"
            >Hi, I'm
            <span class="fw-bold" style="color: #3700e8">Joey!</span></span
          >

          <br />
          <div class="mt-3 fs-3">
            <span class="animate-later fw-normal">
              a
              <span class="fw-bolder"> passionate fullstack developer</span>
            </span>

            <br />
            <span class="animate-later fw-normal"
              >from Kuala Lumpur, Malaysia.</span
            >
            <br />
            <Button
              @click="sideNavActive = true"
              variant="primary"
              style="background-color: #3700e8; padding: 0.5rem !important"
              class="fade-in mt-2 fw-600"
              >Learn more about me!</Button
            >
          </div>
        </div>
      </div>

      <div></div>
    </div>
  </div>

  <SideNav :active="sideNavActive" @dismiss-nav="sideNavActive = false" />
  <SideNavWithButton icon-color="#3700e8" />
</template>

<script setup>
import { onMounted, ref } from "vue";
import { gsap } from "gsap";

import { TextPlugin } from "gsap/TextPlugin";

import Button from "@/components/uielements/button/Button.vue";
import SideNav from "@/components/SideNav/Index.vue";
import SideNavWithButton from "@/components/SideNav/SideNavWithButton.vue";

gsap.registerPlugin(TextPlugin);

const boxRef = ref(null);
const leftEye = ref(null);
const rightEye = ref(null);
const leftBrow = ref(null);
const rightBrow = ref(null);
const mouth = ref(null);

onMounted(() => {
  // GSAP animations
  gsap.from(".portrait-container img", {
    scale: 0.8, // Initial scale
    opacity: 0, // Start with opacity 0
    duration: 1,
    ease: "bounce", // Bounce out easing effect
  });

  gsap.fromTo(
    ".content",
    { opacity: 0, y: -50 },
    { opacity: 1, y: 0, duration: 1, delay: 0.5 }
  );

  gsap.fromTo(
    ".content .intro-text:not(.animate-later)",
    { opacity: 0, y: -50 },
    { opacity: 1, y: 0, duration: 1, delay: 0.5 }
  );

  gsap.fromTo(
    ".content .animate-later",
    { opacity: 0, y: -50 },
    { opacity: 1, y: 0, duration: 1, delay: 1.5 }
  );

  gsap.fromTo(
    ".fade-in",
    { opacity: 0 },
    { opacity: 1, duration: 1, delay: 1.5 }
  );

  // Mouse move effect
  const boxElement = boxRef.value;
  const leftEyeElement = leftEye.value;
  const rightEyeElement = rightEye.value;
  const leftBrowElement = leftBrow.value;
  const rightBrowElement = rightBrow.value;
  const mouthElement = mouth.value;

  if (
    boxElement &&
    leftEyeElement &&
    rightEyeElement &&
    leftBrowElement &&
    rightBrowElement &&
    mouthElement
  ) {
    boxElement.addEventListener("mousemove", (event) => {
      const { clientX: mouseX, clientY: mouseY } = event;

      const boxRect = boxElement.getBoundingClientRect();
      const boxCenterX = boxRect.left + boxRect.width / 2;
      const boxCenterY = boxRect.top + boxRect.height / 2;

      // Calculate mouth tilt and rotation based on mouse position
      const mouthRotationMax = 18; // Maximum rotation angle
      const mouthTiltMax = 10; // Maximum tilt amount

      const mouseXPercentage = (mouseX - boxCenterX) / boxRect.width;
      const mouseYPercentage = (mouseY - boxCenterY) / boxRect.height;

      const mouthRotation = mouseXPercentage * mouthRotationMax;
      const mouthTilt = mouseYPercentage * mouthTiltMax;

      gsap.to(mouthElement, {
        rotation: mouthRotation,
        y: mouthTilt,
        duration: 0.3,
        ease: "power2.out",
      });

      // Calculate the vertical movement relative to the center of the box
      const verticalMovement = mouseY - boxCenterY;

      // Adjust brow position based on vertical movement
      const browMaxOffset = 10; // Adjust as needed
      const browOffsetY = (verticalMovement / boxRect.height) * browMaxOffset;

      // Move brows up or down based on the direction of mouse movement
      gsap.to(leftBrowElement, {
        y: -Math.abs(browOffsetY),
        duration: 0.3,
      });

      gsap.to(rightBrowElement, {
        y: -Math.abs(browOffsetY),
        duration: 0.3,
      });

      const eyeMaxOffset = 18;
      const eyeOffsetX =
        ((mouseX - boxRect.left - boxRect.width / 2) / boxRect.width) *
        eyeMaxOffset;
      const eyeOffsetY =
        ((mouseY - boxRect.top - boxRect.height / 2) / boxRect.height) *
        eyeMaxOffset;

      gsap.to(leftEyeElement, {
        x: eyeOffsetX,
        y: eyeOffsetY,
        duration: 0.3,
      });

      gsap.to(rightEyeElement, {
        x: eyeOffsetX,
        y: eyeOffsetY,
        duration: 0.3,
      });
    });
  }

  const hoverElements = document.querySelectorAll(".fade-in");

  hoverElements.forEach((element) => {
    element.addEventListener("mouseenter", () => {
      gsap.to(element, {
        scale: 1.03, // Zoom in
        duration: 0.3, // Animation duration
        ease: "power2.out", // Easing function
      });
    });

    element.addEventListener("mouseleave", () => {
      gsap.to(element, {
        scale: 1, // Reset to original size
        duration: 0.3, // Animation duration
        ease: "power2.out", // Easing function
      });
    });
  });
});

// Side Nav
const sideNavActive = ref(false);
</script>

<style scoped>
html,
body {
  overflow-y: hidden !important;
  display: flex;
  justify-content: center;
  margin: 0;
}

*,
*::before,
*::after {
  margin: 0 !important;
  padding: 0 !important;
}

.box {
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.portrait-container {
  position: relative;
}

.face {
  width: 400px;
  position: relative;
}

.eye-image {
  position: absolute;
}

.right-brow {
  width: 70px;
  top: 186px;
  left: 210px;
}

.left-brow {
  width: 70px;
  top: 186px;
  left: 110px;
}

.left-eye {
  width: 50px;
  top: 209px;
  left: 124px;
}

.right-eye {
  width: 50px;
  top: 207px;
  left: 210px;
}

.mouth {
  width: 64px;
  top: 295px;
  left: 168px;
  position: absolute;
}

.content {
  font-size: 24px;
}

.intro-text {
  font-size: 2.5em;
  line-height: 1em;
}

.fade-in {
  opacity: 0;
}

@media (max-width: 991.98px) {
  .col-lg-6 {
    justify-content: center !important;
  }

  .hide-on-small-screens {
    display: none;
  }
}
</style>
