<template>
  <div class="backdrop" @click="closeSideNav"></div>

  <div class="side-nav p-4">
    <div class="col-12 text-end">
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        @click="closeSideNav"
        style="width: 30px"
      ></button>
    </div>

    <div class="d-flex align-items-center">
      <h3
        class="fw-bold user-select-none"
        style="cursor: pointer"
        @click="show.myProjects = !show.myProjects"
      >
        My Projects
      </h3>
      <i
        class="bi bi-chevron-down ms-2 fs-6 fw-bold chevron-icon mb-2"
        :class="{ 'rotate-chevron': show.myProjects }"
      ></i>
    </div>
    <transition
      name="fade"
      @before-enter="beforeEnter"
      @enter="enter"
      @leave="leave"
    >
      <div v-if="show.myProjects">
        <div class="fs-5" @click="navigateTo('/poke')" style="cursor: pointer">
          Pokemon Quiz
        </div>
        <div class="fs-5" @click="navigateTo('/ws')" style="cursor: pointer">
          WhatsApp Link Generator
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { defineEmits, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

const emit = defineEmits(["close-side-nav"]);

const show = ref({
  myProjects: false,
});

function closeSideNav() {
  emit("close-side-nav");
}

function navigateTo(route) {
  router.push(route);
}

function beforeEnter(el) {
  el.style.opacity = 0;
  el.style.height = "0";
}

function enter(el, done) {
  el.offsetHeight; // trigger reflow
  el.style.transition = "opacity 0.5s, height 0.5s";
  el.style.opacity = 1;
  el.style.height = "auto";
  done();
}

function leave(el, done) {
  el.style.transition = "opacity 0.5s, height 0.5s";
  el.style.opacity = 0;
  el.style.height = "0";
  done();
}
</script>

<style scoped>
.side-nav {
  position: fixed;
  top: 0;
  right: 0;
  width: 400px;
  height: 100%;
  background-color: white;
  padding: 1rem;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
  z-index: 1000;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.rotate-chevron {
  transition: transform 0.3s ease;
  transform: rotate(180deg);
}

.chevron-icon {
  transition: transform 0.3s ease;
}
</style>
