<template>
  <n-popover
    :overlap="overlap"
    placement="right-start"
    trigger="hover"
    :theme="darkTheme"
  >
    <template #trigger>
      <i
        class="bi bi-house home-trigger fs-4"
        :style="{ color: props.iconColor }"
        @click="goToHome"
      ></i>
    </template>
    <div>Home</div>
  </n-popover>

  <n-popover
    :overlap="overlap"
    placement="left-start"
    trigger="hover"
    :theme="darkTheme"
  >
    <template #trigger>
      <i
        class="bi bi-layout-text-sidebar drawer-trigger fs-4"
        :style="{ color: props.iconColor }"
        @click="sideNavActive = true"
      ></i>
    </template>
    <div>Menu</div>
  </n-popover>

  <SideNav :active="sideNavActive" @dismiss-nav="sideNavActive = false" />
</template>

<script setup>
import SideNav from "@/components/SideNav/Index.vue";
import { ref, defineProps } from "vue";
import { useRouter } from "vue-router";
import { NPopover, darkTheme } from "naive-ui";

const props = defineProps({
  iconColor: {
    type: String,
    required: false,
    default: "blue",
  },
});

const sideNavActive = ref(false);
const router = useRouter();

function goToHome() {
  router.push({
    path: "/",
  });
}
</script>

<style scoped>
.drawer-trigger {
  position: absolute;
  top: 10px;
  right: 10px;
}

.home-trigger {
  position: absolute;
  top: 10px;
  left: 10px;
}

.drawer-trigger,
.home-trigger {
  position: absolute;
}

.drawer-trigger:hover,
.home-trigger:hover {
  cursor: pointer;
}
</style>
