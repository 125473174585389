<template>
  <div class="mockup-phone d-inline-block">
    <div class="camera top-0 left-0"></div>

    <div class="display-bg">
      <div class="artboard phone-size artboard-bg flex flex-column">
        <div class="phone-status-bar" style="background: #074e46; height: 35px">
          <div class="phone-status-icon-bar"></div>
        </div>
        <div
          class="phone-msg-direction"
          style="background: #075e55; height: 45px"
        >
          <div class="col w-100 px-3 pt-2 text-white">
            <i class="bi bi-chevron-left mt-2 me-3"></i>

            <span v-if="props.validNumber"> {{ props.fullPhoneNumber }}</span>
          </div>
        </div>
        <div id="text-message">
          <slot> </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
const props = defineProps({
  fullPhoneNumber: {
    type: String,
    default: () => "",
  },
  validNumber: {
    type: Boolean,
    default: () => false,
  },
});
</script>

<style scoped>
.mockup-phone {
  border: 0.25rem solid #444;
  border-bottom: 0;
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
  background-color: #000;
  padding: 0.625rem;
  padding-bottom: 0;
  margin: 0 auto;
  overflow: hidden;
}

.wifi {
  padding-bottom: 8%;
}
.camera {
  position: relative;
  background: #000;
  height: 1.6rem;
  /*width: 9.375em;*/
  width: 6.4rem;
  margin: 0 auto;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  z-index: 11;
}

.camera::before {
  content: "";
  position: absolute;
  top: 35%;
  left: 50%;
  width: 3rem;
  height: 0.25rem;
  border-radius: 0.3rem;
  background-color: #141310;
  transform: translate(-50%, -50%);
}

.camera::after {
  content: "";
  position: absolute;
  top: 20%;
  left: 70%;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 0.3rem;
  background-color: #191e4f;
}

.display-bg {
  overflow: hidden;
  margin-top: -1.5rem;
}

.display-bg,
.artboard-bg {
  border-top-left-radius: 2.5rem;
  border-top-right-radius: 2.5rem;
}

.phone-size {
  /* height(df:520) / width = 1.625*/
  height: 440px;
  width: 240px;
}

.artboard-bg {
  background-color: #fff;
  border-bottom: 0;
  box-shadow: 0 1px 3px #0000001a, 0 1px 2px #0000000f;
}

.phone-status-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0.15rem 0;
}

.phone-time {
  padding: 0 1rem 0 1.75rem;
  font-size: 0.75rem;
}

.phone-time,
.phone-status-icon-bar {
  position: relative;
  top: 0.25rem;
  font-weight: 600;
}

.phone-status-icon-bar {
  padding: 0 1rem 0 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.phone-status-bar,
.phone-msg-direction {
  background-color: #f4f5f5;
}

.phone-status-icon-bar i {
  font-size: 0.8rem;
}

.phone-status-icon-bar i,
.phone-status-icon-bar img {
  margin: 0 0.15rem;
  color: #1d2129;
}

.phone-status-icon-bar img {
  width: 0.75rem;
}

.phone-msg-direction {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.4rem 0 1rem 0;
}

.chevron-left {
  position: absolute;
  left: 0.55rem;
  width: 1rem;
  height: 1rem;
}

.receiver {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.receiver-profile {
  width: 2.75rem;
  height: 2.75rem;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.receiver-profile > span {
  font-size: 1rem;
  font-weight: 600;
  color: var(--primary-red);
}

#text-message {
  display: flex;
  flex-direction: column;
  margin: 0.75rem 0;
}

.message-info {
  color: #86909c;
  font-size: 0.65rem;
  text-align: center;
}
</style>
