<template>
  <div v-if="isMobile" class="p-2">
    <img
      src="@/assets/png/sad-cat.png"
      alt="sad-cat"
      class="responsive-image"
    />
    <div class="h2 mt-2">
      Sorry, this site is not optimized for mobile. Kindly access using your PC.
      Thank You!
    </div>
  </div>
  <router-view v-else />
</template>

<script setup>
import { computed } from "vue";

const isMobile = computed(() => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
});
</script>

<style>
html {
  overflow-x: hidden;
  overflow-y: auto;
}

.responsive-image {
  max-width: 100%; /* Ensure the image doesn't exceed the container's width */
  height: auto; /* Maintain aspect ratio */
  display: block; /* Remove any inline spacing */
  margin: 0 auto; /* Center the image horizontally */
}

body {
  font-family: "Inter", sans-serif !important;
}
</style>
